import * as React from "react";
import HelpPanel from "@amzn/awsui-components-react-v3/polaris/help-panel";
import Icon from "@amzn/awsui-components-react-v3/polaris/icon";

const AMLETHelpPanel = () => {
    return (
        <HelpPanel
            footer={
                <div>
                    <h3>
                        Learn more{" "}
                        <Icon name="external" size="inherit" />
                    </h3>
                    <ul>
                        <li>
                            <a href="https://w.amazon.com/bin/view/PaymentRisk/FeatureEngineering/Engineering/Projects/AMLET/">AMLET High Level Design</a>
                        </li>
                        <li>
                            <a href="https://w.amazon.com/bin/view/PaymentRisk/FeatureEngineering/Engineering/Projects/AMLET/UserExperience/">AMLET User Experience with SAIS</a>
                        </li>
                    </ul>
                </div>
            }
            header={<h2>About AMLET</h2>}
        >
            <div>
                <p>
                    AMLET (Amazon ML Experimentation Tool) is used to experiment and compare model performances against
                    current existing algorithms/ML models in a standardized manner.
                </p>

                <h3>How to onboard to AMLET?</h3>
                <p>
                    For detailed onboarding steps, refer to the <a href="https://quip-amazon.com/rDxrAURlR514">AMLET User Experience with SAIS</a> document.
                </p>

                <h3>Why use AMLET?</h3>
                <ul>
                    <li>Standardized comparison of model performances</li>
                    <li>Easy experimentation and benchmarking</li>
                    <li>Simplified validation process for reviewers</li>
                    <li>Integration with existing SAIS and MODS infrastructure</li>
                    <li>Centralized dashboard for model performance visualization and comparison</li>
                </ul>

                <h3>Key Components</h3>
                <ul>
                    <li>SAIS Integration: Run AMLET within your Secure AI Sandbox environment</li>
                    <li>UMLC Integration: Store and retrieve model metadata and performance metrics</li>
                    <li>SandBox UI Dashboard: Visualize and compare model performances</li>
                </ul>

                <h3>Need Help?</h3>
                <p>
                    For any questions or issues, please contact the <a href="https://permissions.amazon.com/a/team/amzn1.abacus.team.5nlkjyrbws77sesn5bwa">pre-fe</a> or refer to the documentation linked below.
                </p>
            </div>
        </HelpPanel>
    );
}

export default AMLETHelpPanel;

import React, { Component } from "react";
import { BrowserRouter as Router, Redirect, Route, Switch } from "react-router-dom";

import NavBar from "../navigation/navbar.js";
import Home from "../pages/home/home.js";
import AMLETDashboard from "../pages/amlet/Amlet";
import Account from "../pages/account/account.js";
import Sandboxes from "../pages/sandboxes/sandboxes.js";
import CreatePage from "../pages/create/Create";
import CreateSchedule from "../pages/createschedule/Create"
import Schedules from "../pages/schedules/schedules.js"
import SchedulesDetails from "../pages/schedules/schedules-detail.js"
import Update from "../pages/update/update.js";
import Workflows from "../pages/workflows/workflows.js";
import WorkflowDetails from "../pages/workflows/workflows-detail.js";
import WorkflowExecutions from "../pages/workflow_executions/workflow-executions.js";
import WorkflowsExecute from "../pages/workflows/workflows-execute";
import ExecutionDetails from "../pages/workflow_executions/workflow-execution-detail";
import AppContext, { AppContextInterface } from "../context/AppContext";

import "./app.css";
import { AxiosResponse } from "axios";
import { CognitoAuth, CognitoAuthSession } from "amazon-cognito-auth-js";
import { SandboxManagementClient } from "../client/sandbox-management-client";
import { MODSWorkflowManagementClient } from "../client/mods-workflow-management-client";
import { getDevelopmentSettings, removeQueryFromLocation } from "../auth/login";
import { PageStage } from "../constants/constants";


const axios = require("axios");

interface AppState extends AppContextInterface { }

interface AppProps { }

interface Settings {
    cognitoDomain: string;
    cognitoClientId: string;
    domain: string;
    cognitoUserPoolId: string;
    sandboxManagementServiceApi: string;
    modsWorkflowManagementServiceApi: string;
}

class App extends Component<AppProps, AppState> {
    static contextType = AppContext;
    loginSessionRefreshIntervalID;

    constructor(props?: any, context?: any) {
        super(props, context);
        this.state = {
            ...this.state,
            pageStage: PageStage.SETTINGS_LOADING_REQUIRED,
        };
    }

    // <-------------------------------- Page State Helper --------------------------------> //
    updatePageStage(newPageStage: PageStage) {
        let prevPageStage = this.state.pageStage;
        console.debug(`Webpage stage moved. New stage is ${newPageStage}. Previous stage is ${prevPageStage}.`);
        this.setState({ ...this.state, pageStage: newPageStage });
    }

    // <------------------------------ Client initialization ------------------------------> //
    async initClientAndAuth(data: any) {
        const isDev = !process.env.NODE_ENV || process.env.NODE_ENV === "development";

        const cognitoAuth = new CognitoAuth({
            AppWebDomain: data.cognitoDomain,
            ClientId: data.cognitoClientId,
            RedirectUriSignIn: isDev ? data.domain : `https://${data.domain}`,
            RedirectUriSignOut: isDev ? data.domain : `https://${data.domain}`,
            TokenScopesArray: ["openid", "email"],
            UserPoolId: data.cognitoUserPoolId,
        });

        const sandboxManagementClient = new SandboxManagementClient(data.sandboxManagementServiceApi, cognitoAuth);
        console.debug("SandboxManagementClient Created. Set the auth and client to context. Current settings: ", data);
        const modsWorkflowManagementClient = new MODSWorkflowManagementClient(data.modsWorkflowManagementServiceApi, cognitoAuth);
        console.debug("MODSWorkflowManagementClient Created. Set the auth and client to context. Current settings: ", data);

        this.setState({ ...this.state, auth: cognitoAuth, sandboxManagementClient: sandboxManagementClient, modsWorkflowManagementClient: modsWorkflowManagementClient });
    }

    // <------------------------------ Configure Authorizer ------------------------------> //
    /**
     * Configure the Cognito auth after it has been created.
     * Please note invoke the method prior to the auth being created will lead to exception.
     */
    configAuth() {
        console.debug("Start init auth client.");
        const auth = this.state.auth;
        auth.useCodeGrantFlow();
        auth.userhandler = {
            onFailure: (err: any) => {
                console.debug('Cognito login onFailure with error: ', err);
                const auth = this.state.auth;
                // Check if the user ever successfully signed in
                // The AuthClient use it as indicator to refresh the session instead to get a new one:
                // https://code.amazon.com/packages/NodeJS-amazon-cognito-auth-js/blobs/2dcbdc8b431f3eb04/--
                // /amazon-cognito-auth-js/src/CognitoAuth.js#L326-L328,L218-L221
                const lastSignInUser = auth.getLastUser();

                if (lastSignInUser) {
                    // Clean the expired cached tokens to force request a new session in next get
                    auth.clearCachedTokensScopes();
                    // Set the page state back to login required
                    this.updatePageStage(PageStage.LOGIN_REQUIRED);
                    // Explicitly trigger create a new session. Otherwise we will stuck under login required forever.
                    auth.getSession();
                } else {
                    removeQueryFromLocation();
                    this.updatePageStage(PageStage.LOGIN_FAILED);
                }
            },
            onSuccess: (result: CognitoAuthSession) => {
                console.debug('Cognito login onSuccess');
                // Continue to next domain
                removeQueryFromLocation();
                this.updatePageStage(PageStage.LOGGING_IN);
                this.postUserLoginProcessing()
            },
        }
        this.updatePageStage(PageStage.LOGIN_REQUIRED);
    }

    /**
     * Helper function to be invoked on login success. This function will
     * 1. populate the current user identity to local storage if the session is ready, or
     * 2. parse the Cognito response
     * 3. refresh the session if it has been expired.
     *
     * If enter this method when session is valid, it will also move the current page stage forward to
     * FULLY_LOADED in order to allow the full context to be loaded.
     */
    postUserLoginProcessing() {
        const href = window.location.href;
        const auth = this.state.auth;
        const session = auth.getSignInUserSession();

        if (session.isValid()) {
            console.debug(`Session is valid. Store the user alias to local storage.`);
            const payload: any = auth.getSignInUserSession().getIdToken().decodePayload();
            localStorage.setItem(
                "userId",
                payload.identities[0].userId
            );
            this.updatePageStage(PageStage.FULLY_LOADED);
        } else if (href.indexOf('?') > 0) {
            // This is required because Cognito needs to get the authentication result from the query string
            // The parsing is done asynchronously, and the result will be passed to the userHandler.
            console.debug(`Parsing session for user`);
            auth.parseCognitoWebResponse(href);
            console.debug('Parsed session');
        } else {
            // Cognito SDK will handle session refresh / authentication
            auth.getSession();
        }
    }

    ////// Initialization helping methods //////
    /**
     * Initialize the sandbox management client and authorization with settings for local test
     */
    async initWithLocalTestSettings() {
        console.debug("Local test. Using default settings.");
        const testSettings = getDevelopmentSettings()
        this.initClientAndAuth(testSettings)
            .then(() => {
                this.updatePageStage(PageStage.SETTINGS_LOADED);
                this.configAuth();
            });
    }

    /**
     * Initialize the sandbox management client and authorization with settings for current stage
     */
    async initWithSettingsFromRemote() {
        axios.get("/settings.json")
            .then((r: AxiosResponse<Settings>) => {
                this.initClientAndAuth(r.data);
                this.updatePageStage(PageStage.SETTINGS_LOADED);
            })
            .then(() => {
                this.configAuth()
            })
            .catch((error: any) => {
                console.warn(`Failed to load setting file from remote ${error}. Failed back to use default` +
                    " development setting");
                this.updatePageStage(PageStage.LOGIN_FAILED);
            });
    }

    /**
     * Initialize the sandbox management client and authorization with settings selected based on the current
     * NODE_ENV value.
     */
    async initialization() {
        const isDev = !process.env.NODE_ENV || process.env.NODE_ENV === "development";
        if (isDev) {
            await this.initWithLocalTestSettings();
        } else {
            await this.initWithSettingsFromRemote();
        }
    }

    // <----------------------------- Post initialization -----------------------------> //
    refreshSignInSession() {
        const refreshInterval = 2 * 60 * 60 * 1000; // Refresh every 2 hour
        if (this.state.pageStage < PageStage.FULLY_LOADED) {
            console.debug(`Page is not ready. Skip the current refresh. Will refresh in ${refreshInterval} ms.`);
        } else {
            const auth = this.state.auth;
            const href = window.location.href;
            const session = auth.getSignInUserSession();

            if (session.isValid()) {
                // Do nothing
                console.debug("Session is valid. Do nothing");
            } else if (href.indexOf("?") > 0) {
                auth.parseCognitoWebResponse(href);
            } else {
                auth.getSession();
            }
            console.debug(`Session refresh complete. Will refresh in ${refreshInterval} ms.`);
        }

        this.loginSessionRefreshIntervalID = setTimeout(this.refreshSignInSession.bind(this), refreshInterval);
    }

    // <-------------------------------- React handlers -------------------------------> //
    async componentDidMount() {
        console.debug("App mounted.");
        await this.initialization();

        // Only start the refresh after first session is valid
        this.refreshSignInSession();
    }

    componentDidUpdate(prevProps: Readonly<AppProps>, prevState: Readonly<AppState>, snapshot?: any) {
        console.debug("App updated. Previous page State is: ", prevState.pageStage);
        if (prevState.pageStage !== this.state.pageStage) {
            if (this.state.pageStage > PageStage.SETTINGS_LOADED && this.state.pageStage !== PageStage.LOGIN_FAILED) {
                this.postUserLoginProcessing();
            }
        }
    }

    componentWillUnmount() {
        // stop refreshSignInSession() from continuing to run even after unmounting this component.
        clearTimeout(this.loginSessionRefreshIntervalID);
    }

    render() {
        return (
            <AppContext.Provider value={this.state}>
                <Router>
                    <NavBar />
                    <Switch>
                        <Route exact path="/" component={Home} />
                        <Route exact path="/account" component={Account} />
                        <Route exact path="/sandboxes" component={Sandboxes} />
                        <Route exact path="/create" component={CreatePage} />
                        <Route exact path="/update/" component={Update} />
                        <Route exact path="/update/:sandboxId" component={Update} />
                        <Route exact path="/workflows" component={Workflows} />
                        <Route exact path="/create_schedules" component={CreateSchedule} />
                        <Route exact path="/schedules" component={Schedules} />
                        <Route exact path="/schedules/:scheduleId" component={SchedulesDetails} />
                        <Route exact path="/workflows/:gitfarmPackage/:templateName/:templateVersion" component={WorkflowDetails} />
                        <Route exact path="/workflows/:gitfarmPackage/:templateName/:templateVersion/execute" component={WorkflowsExecute} />
                        <Route exact path="/workflow_executions" component={WorkflowExecutions} />
                        <Route exact path="/workflow_executions/:executionId" component={ExecutionDetails} />
                        <Route exact path="/amlet-dashboard" component={AMLETDashboard} />
                        <Redirect to="/" />
                    </Switch>
                </Router>
            </AppContext.Provider>
        );
    }
}

export default App;

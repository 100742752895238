import React from "react";

import {SideNavigation} from "@amzn/awsui-components-react";

const header = {
    href: "/",
    text: "Secure AI Sandbox",
};

const rootItem = {type: "link", text: "Home", href: "/"}
const userGuideItem = {
    type: "link",
    text: "User Guide",
    href: "https://w.amazon.com/bin/view/CMLS/SecureAISandbox/GettingStarted"
}

export const full_items = [
    rootItem,
    {type: "link", text: "My Account", href: "/account"},
    {type: "link", text: "My Sandboxes", href: "/sandboxes"},
    {type: "link", text: "Create Instance", href: "/create"},
    {type: "link", text: "Update Instance", href: "/update"},
    {type: "link", text: "My Workflows", href: "/workflows"},
    {type: "link", text: "Workflow Executions", href: "/workflow_executions"},
    {type: "link", text: "Create Schedule", href: "/create_schedules"},
    {type: "link", text: "My Schedules", href: "/schedules"},
    {type: "link", text: "AMLET Dashboard", href: "/amlet-dashboard"},
    userGuideItem
];


export const home_page_only = [
    rootItem,
    userGuideItem
];

function SideBar(props) {
    return <SideNavigation activeHref={props.activeHref} header={header} items={props.items}></SideNavigation>;
}

export default SideBar;

import {MODSWorkflowManagementClient} from "../../client/mods-workflow-management-client";
import {GremlinQueryResultClass} from "../../util/umlc-gremlin-query-utils";

// Interface defining the structure of a model test result
interface ModelTestResult {
    experimentId: string;
    namespace: string;
    region: string;
    createdAt: Date;
    modelName: string;
    modelType: string;
    dataset: string;
    modelAuthor: string;
    manualUserAcceptance: boolean;
    [key: string]: string | number | Date | boolean; // This allows for dynamic metric fields
}

// Generates a Gremlin query to fetch the current user's models
export const getMyModelsQuery = (currentUser: string) => `
  g.V().hasLabel("Test")
    .has("namespace", "AMLET")
    .has("startedBy", "${currentUser}")
    .project("name", "namespace", "region", "result", "createdAt", "startedBy", "modelName", "modelType", "datasetName","document")
    .by("$uri")
    .by("namespace")
    .by("region")
    .by("result")
    .by("createdAt")
    .by("startedBy")
    .by(coalesce(outE("tested").inV().values("$uri"), constant("No model name available")))
    .by(coalesce(outE("tested").inV().values("modelType"), constant("No model type available")))
    .by(coalesce(outE("whenUsedWith").inV().values("$uri"), constant("No dataset name available")))
    .by("$document")
`;

// Extracts and formats search results from the Gremlin query response
function extractSearchResults(result: any) {
    const gremlinResult = GremlinQueryResultClass.fromJSON(result.data.queryResult);
    const rawResults = gremlinResult.readValues(['name', 'namespace', 'region', 'result',
        'createdAt', 'startedBy','modelName', 'modelType', 'datasetName',"document"]);

    return rawResults.map(item => {
        let resultString: string;
        resultString = item.result;
        const resultObject: Record<string, number> = {};

        // Parse the result string into key-value pairs
        resultString.slice(1, -1).split(',').forEach(pair => {
            const [key, value] = pair.split(':');
            resultObject[key.trim()] = parseFloat(value.trim());
        });

        // Parse the document to extract manualUserAcceptance
        let manualUserAcceptance = false;
        if (item.document) {
            const lowercaseDocument = item.document.toLowerCase();
            const regex = /"manualuseracceptance"\s*:\s*(true|false)/;
            const match = lowercaseDocument.match(regex);

            if (match) {
                manualUserAcceptance = match[1] === 'true';
            }
        }

        const baseResult: ModelTestResult = {
            experimentId: item.name,
            namespace: item.namespace,
            region: item.region,
            createdAt: new Date(item.createdAt),
            modelName: item.modelName,
            modelType: item.modelType,
            dataset: item.datasetName,
            modelAuthor: item.startedBy,
            manualUserAcceptance: manualUserAcceptance
        };

        return {...baseResult, ...resultObject};
    });
}

// Fetches and processes the results of the user's models
export const GetMyModelsResult = (modsWorkflowManagementClient: MODSWorkflowManagementClient, currentUser: string): Promise<ModelTestResult[]> => {
    let personalizedQuery = cleanQuery(getMyModelsQuery(currentUser));

    return modsWorkflowManagementClient
        .executeGremlinQueryCognito(personalizedQuery)
        .then((result) => {
            if(!result){
                throw new Error('Failed to get a response');
            }
            if (result.status !== 200) {
                throw new Error('Failed to fetch data');
            }

            return extractSearchResults(result);
        })
        .catch((error) => {
            console.error('Error fetching data:', error);
            throw error;
        });
}

// Generates a Gremlin query for searching models based on provided parameters
export const getSearchModelsQuery = (params: {
    modelType?: string;
    dataset?: string;
    modelName?: string;
    modelAuthor?: string;
    startDate?: string;
    endDate?: string;
}) => {
    let query = `
        g.V().hasLabel("Test")
        .has("namespace", "AMLET")
    `;

    if (params.modelType && params.modelType !== "All_Types") {
        query += `.where(outE("tested").inV().has("modelType", "${params.modelType}"))`;
    }

    if (params.dataset) {
        query += `.where(outE("whenUsedWith").inV().has("$uri", "${params.dataset}"))`;
    }

    if (params.modelName) {
        query += `.where(outE("tested").inV().has("$uri", "${params.modelName}"))`;
    }

    if (params.modelAuthor) {
        query += `.has("startedBy", "${params.modelAuthor}")`;
    }

    if (params.startDate && params.endDate) {
        query += `.has("createdAt", between(datetime("${params.startDate}"), datetime("${params.endDate}")))`;
    }

    query += `
        .project("experimentId", "namespace", "region", "result", "createdAt", "modelAuthor", "modelName", "modelType", "dataset")
        .by("$uri")
        .by("namespace")
        .by("region")
        .by("result")
        .by("createdAt")
        .by("startedBy")
        .by(coalesce(outE("tested").inV().values("$uri"), constant("No model name available")))
        .by(coalesce(outE("tested").inV().values("modelType"), constant("No model type available")))
        .by(coalesce(outE("whenUsedWith").inV().values("$uri"), constant("No dataset name available")))
    `;

    return query;
};

// Executes a search for models based on the provided parameters
export const SearchModelsResult = (
    modsWorkflowManagementClient: MODSWorkflowManagementClient,
    params: {
        modelType?: string;
        dataset?: string;
        modelName?: string;
        modelAuthor?: string;
        startDate?: string;
        endDate?: string;
    }
): Promise<ModelTestResult[]> => {
    let searchQuery = cleanQuery(getSearchModelsQuery(params));

    return modsWorkflowManagementClient
        .executeGremlinQueryCognito(searchQuery)
        .then((result) => {
            if(!result){
                throw new Error('Failed to get a response');
            }
            if (result.status !== 200) {
                throw new Error('Failed to fetch data');
            }

            return extractSearchResults(result);
        })
        .catch((error) => {
            console.error('Error fetching data:', error);
            throw error;
        });
};

// Converts a GremlinQueryResult to an array of strings
function readValuesAsStringArray(gremlinResult: any) {
    if (Array.isArray(gremlinResult.value) && gremlinResult.value.every(item => typeof item === 'string')) {
        return gremlinResult.value;
    }
    throw new Error('Unexpected result format');
}

// Fetches and returns a list of model names
export const GetModelNames = async (modsWorkflowManagementClient: MODSWorkflowManagementClient): Promise<string[]> => {
    let query =   `g.V().hasLabel('Model')
        .has('namespace', 'AMLET')
        .values('name')
        `;
    query = cleanQuery(query);

    try {
        let result = await modsWorkflowManagementClient
            .executeGremlinQueryCognito(query);
        if(!result){
            throw new Error('Failed to get a response');
        }
        if (result.status !== 200) {
            throw new Error('Failed to fetch data');
        }
        const gremlinResult = GremlinQueryResultClass.fromJSON(result.data.queryResult);
        return readValuesAsStringArray(gremlinResult);
    } catch (error) {
        console.error('Error fetching model names:', error);
        throw error;
    }
}

// Fetches and returns a list of dataset names
export const GetDatasetNames = (modsWorkflowManagementClient: MODSWorkflowManagementClient): Promise<string[]> => {
    let query = `
      g.V().hasLabel('Stream')
        .has('namespace', 'AMLET')
        .has('$type', 'ModelingData')
        .values('name')
      `;
    query = cleanQuery(query);

    return modsWorkflowManagementClient
        .executeGremlinQueryCognito(query)
        .then((result) => {
            if(!result){
                throw new Error('Failed to get a response');
            }
            if (result.status !== 200) {
                throw new Error('Failed to fetch data');
            }
            const gremlinResult = GremlinQueryResultClass.fromJSON(result.data.queryResult);
            return readValuesAsStringArray(gremlinResult);
        })
        .catch((error) => {
            console.error('Error fetching dataset names:', error);
            throw error;
        });
}

// Function to remove all newline characters and escape all double quotes in the query
function cleanQuery(query: string) {
    return query.replace(/\n/g, '').replace(/"/g, '\\"');
}
export const ALL_EXPERIMENTS = "allExperiments";
export const YOUR_EXPERIMENTS = "yourExperiments";
export const USER_ID = "userId";


export const supportedModelTypes = [
    "All_Types",
    "TABULAR_BINARY_CLASSIFICATION",
    "TABULAR_MULTICLASS_CLASSIFICATION",
    "TABULAR_REGRESSION"
]

export const UMLC_BASE_URLS = {
    "dev":"https://test.unified-ml-catalog.ctps.amazon.dev/catalog-document/",
    "prod":"https://unified-ml-catalog.ctps.amazon.dev/catalog-document/"
};
import * as React from "react";
import Toggle from "@amzn/awsui-components-react-v3/polaris/toggle";
import Icon from "@amzn/awsui-components-react-v3/polaris/icon";
import AppContext from "../../context/AppContext";

const DashboardVisibilityToggle = ({model}) => {
    const { modsWorkflowManagementClient } = React.useContext(AppContext);
    const [checked, setChecked] = React.useState(model.manualUserAcceptance || false);
    const [isLoading, setIsLoading] = React.useState(false);

    const handleToggleChange = async ({ detail }) => {
        setIsLoading(true);
        setChecked(detail.checked);
        updateDashboardVisibility(model, detail.checked)
            .then(resp => {
                setChecked(detail.checked);
                setIsLoading(false);
            })
            .catch(err => {
                console.error("Error updating dashboard visibility:", err);
                setChecked(!detail.checked);
                setIsLoading(false);
            });
    };

    const updateDashboardVisibility = async (model, visible) => {
        console.log(`Updating dashboard visibility for model ${model.experimentId} to ${visible}`);
        const id = model.experimentId.split(":").pop();
        const acceptanceCriteria = `{"manualUserAcceptance": ${visible}}`
        return modsWorkflowManagementClient.publishIsModelPerformanceAcceptableCognito(id, acceptanceCriteria, model.region);
    }

    return (
        <Toggle
            onChange={handleToggleChange}
            checked={checked}
            disabled={isLoading}
        >
            Published to AMLET
            {isLoading && <span> <Icon
                name="lock-private"
                size="small"
                variant="disabled"
            /></span>}
        </Toggle>
    );
}

export default DashboardVisibilityToggle;
import React from "react";
import DashboardVisibilityToggle from "./AMLETDashboardVisibilityToggle";
import { UMLC_BASE_URLS } from "../../constants/amlet_constants";

const isDev = !process.env.NODE_ENV || process.env.NODE_ENV === "development";

const UMLC_BASE_URL = isDev? UMLC_BASE_URLS["dev"]:UMLC_BASE_URLS["prod"];

export const ColumnDefinitions = [
    {
        id: "modelType",
        header: "Model Type",
        cell: item => item.modelType,
        ariaLabel: createLabelFunction('modelType'),
        sortingField: "modelType"
    },
    {
        id: "modelName",
        header: "Model Name",
        cell: item => <a href={UMLC_BASE_URL+item.modelName}>{item.modelName}</a>,
        ariaLabel: createLabelFunction('modelName'),
        sortingField: "modelName",
        isRowHeader: true
    },
    {
        id: "dataset",
        header: "Dataset Name",
        cell: item => <a href={UMLC_BASE_URL+item.dataset}>{item.dataset}</a>,
        ariaLabel: createLabelFunction('dataset'),
        sortingField: "dataset"
    },
    {
        id: "dashboardVisibility",
        header: "Dashboard Visibility",
        cell: item => <DashboardVisibilityToggle model={item}/>,
        ariaLabel: createLabelFunction('dashboardVisibility')
    },
    {
        id: "modelAuthor",
        header: "Model Author",
        cell: item => item.modelAuthor,
        ariaLabel: createLabelFunction('modelAuthor'),
        sortingField: "modelAuthor"
    },
    {
        id: "roc_auc",
        header: "ROC AUC",
        cell: item => item.roc_auc,
        ariaLabel: createLabelFunction('roc_auc'),
        sortingField: "roc_auc"
    },
    {
        id: "average_precision",
        header: "Average Precision",
        cell: item => item.average_precision,
        ariaLabel: createLabelFunction('average_precision'),
        sortingField: "average_precision"
    },
    {
        id: "neg_root_mean_squared_error",
        header: "Neg. Root Mean Squared Error",
        cell: item => item.neg_root_mean_squared_error,
        ariaLabel: createLabelFunction('neg_root_mean_squared_error'),
        sortingField: "neg_root_mean_squared_error"
    },
    {
        id: "neg_mean_absolute_percentage_error",
        header: "Neg. Mean Absolute Percentage Error",
        cell: item => item.neg_mean_absolute_percentage_error,
        ariaLabel: createLabelFunction('neg_mean_absolute_percentage_error'),
        sortingField: "neg_mean_absolute_percentage_error"
    },
    {
        id: "neg_root_mean_squared_log_error",
        header: "Neg. Root Mean Squared Log Error",
        cell: item => item.neg_root_mean_squared_log_error,
        ariaLabel: createLabelFunction('neg_root_mean_squared_log_error'),
        sortingField: "neg_root_mean_squared_log_error"
    },
    {
        id: "neg_log_loss",
        header: "Negative Log Loss",
        cell: item => item.neg_log_loss,
        ariaLabel: createLabelFunction('neg_log_loss'),
        sortingField: "neg_log_loss"
    },
    {
        id: "balanced_accuracy",
        header: "Balanced Accuracy",
        cell: item => item.balanced_accuracy,
        ariaLabel: createLabelFunction('balanced_accuracy'),
        sortingField: "balanced_accuracy"
    },
    {
        id: "createdAt",
        header: "Created At",
        cell: item => item.createdAt,
        ariaLabel: createLabelFunction('createdAt'),
        sortingComparator: (a, b) => a.createdAt.valueOf() - b.createdAt.valueOf(),
    },
    {
        id: "experimentId",
        header: "Experiment Id",
        cell: item => <a href={UMLC_BASE_URL+item.experimentId}>{item.experimentId}</a>,
        ariaLabel: createLabelFunction('experimentId'),
        sortingField: "experimentId"
    },
    {
        id: "region",
        header: "Region",
        cell: item => item.region,
        ariaLabel: createLabelFunction('region'),
        sortingField: "region"
    }
];

export const getMatchesCountText = ({count}) => {
    return count === 1 ? `1 match` : `${count} matches`;
}

export const paginationLabels = {
    nextPageLabel: 'Next page',
    pageLabel: pageNumber => `Go to page ${pageNumber}`,
    previousPageLabel: 'Previous page',
};

const pageSizePreference = {
    title: 'Select page size',
    options: [
        { value: 10, label: '10 experiments' },
        { value: 20, label: '20 experiments' },
    ],
};

function createLabelFunction(columnName) {
    return ({ sorted, descending }) => {
        const sortState = sorted ? `sorted ${descending ? 'descending' : 'ascending'}` : 'not sorted';
        return `${columnName}, ${sortState}.`;
    };
}

const contentDisplayPreference = {
    title: 'Column preferences',
    description: 'Customize the columns visibility and order.',
    options: ColumnDefinitions.map(({ id, header }) => ({ id, label: header, alwaysVisible: id === 'experimentId' })),
};

export const collectionPreferencesProps = {
    pageSizePreference,
    contentDisplayPreference,
    cancelLabel: 'Cancel',
    confirmLabel: 'Confirm',
    title: 'Preferences',
};

